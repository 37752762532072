import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./auth.module";
import apis from "./apis.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import pos from "./pos.module";
import personalize from "./personalize.module";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        "auth.user",
        "auth.token",
        "personalize.store",
        "personalize.viewMode",
        "pos.carts",
        "pos.paymentMethod",
        "pos.tax",
        "pos.discount",
        "pos.grandTotal",
      ],
    }),
  ],
  modules: {
    auth,
    apis,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    pos,
    personalize,
  },
});
