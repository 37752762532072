var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-group',{attrs:{"validator":_vm.v,"label":_vm.label,"description":_vm.description,"label-class":_vm.labelClass,"use-label":_vm.useLabel,"use-horizontal":_vm.useHorizontal}},[_c('date-range-picker',{staticStyle:{"width":"100%","cursor":"pointer"},attrs:{"disabled":_vm.disabled,"opens":"center","locale-data":{ format: 'dd/mm/yyyy', separator: ' to ' },"control-container-class":`form-control ${
      _vm.validate() == false
        ? 'is-invalid'
        : _vm.validate() == true
        ? 'is-valid'
        : null
    }`},on:{"input":function($event){return _vm.v.$touch()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),(_vm.validate() == false)?[_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"inline !important"}},[_vm._v(" "+_vm._s(_vm.label)+" must not be empty ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }