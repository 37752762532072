/* eslint-disable */

import router from "@/router";
import store from "@/core/services/store";
import getPageTitle from "@/core/utils/page-title";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { apiUrl } from "@/core/services/api.url";

const Permission = {
  init() {
    const whiteList = ["/login", "/setup-password"];

    router.beforeEach((to, from, next) => {
      let user = store.getters["auth/user"];
      if (
        to.path != "/logout" &&
        typeof user?.userRole != "undefined" &&
        user?.userRole?.id != 4
      ) {
        let storeId = user.store?.id;
        let businessId =
          process.env.NODE_ENV === "production"
            ? apiUrl.business.prodId
            : apiUrl.business.devId;
        let token = store.getters["auth/token"];
        let redirect =
          process.env.NODE_ENV === "production"
            ? apiUrl.backoffice.prod
            : apiUrl.backoffice.dev;
        redirect = `${redirect}/setup?a1=${token.accessToken}&a2=${token.refreshToken}&a3=${businessId}&from=${window.location.origin}&store=${storeId}`;
        window.open(redirect, "_self").focus();
      }

      document.title = getPageTitle(to.meta.title);
      store.dispatch(RESET_LAYOUT_CONFIG);

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);

      if (store.getters["auth/isLoggedIn"]) {
        if (to.path == "/login") {
          next({ name: "pos" });
        } else {
          next();
        }
      } else {
        if (whiteList.indexOf(to.path) != -1) {
          next();
        } else {
          next(`/login`);
        }
      }
    });
  },
};

export default Permission;
