/* eslint-disable */
export const UPDATE_STORE = "updateStore";
export const UPDATE_VIEW_MODE = "updateViewMode";
export const SET_STORE = "setStore";
export const SET_VIEW_MODE = "setViewMode";

const personalize = {
  // namespaced: true,
  state: {
    store: null,
    viewMode: null,
  },
  getters: {
    store: (state) => state.store,
    viewMode: (state) => state.viewMode,
  },
  mutations: {
    [UPDATE_STORE](state, payload) {
      state.store = payload;
    },
    [UPDATE_VIEW_MODE](state, payload) {
      state.viewMode = payload;
    },
  },
  actions: {
    [SET_STORE]({ commit }, payload) {
      commit(UPDATE_STORE, payload);
    },
    [SET_VIEW_MODE]({ commit }, payload) {
      commit(UPDATE_VIEW_MODE, payload);
    },
  },
};

export default personalize;
