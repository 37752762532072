/* eslint-disable */
export const UPDATE_CART = "updateCart";
export const SET_CART = "setCart";

export const UPDATE_PAYMENT_METHOD = "updatePaymentMethod";
export const SET_PAYMENT_METHOD = "setPaymentMethod";

export const UPDATE_TAX = "updateTax";
export const SET_TAX = "setTax";

export const UPDATE_DISCOUNT = "updateDiscount";
export const SET_DISCOUNT = "setDiscount";

export const UPDATE_GRAND_TOTAL = "updateGrandTotal";
export const SET_GRAND_TOTAL = "setGrandTotal";

export const UPDATE_MEMBER = "updateMember";
export const SET_MEMBER = "setMember";

const pos = {
  // namespaced: true,
  state: {
    carts: [],
    paymentMethod: {},
    tax: 0,
    discount: 0,
    grandTotal: 0,
    member: {},
  },
  getters: {
    carts: (state) => state.carts,
    paymentMethod: (state) => state.paymentMethod,
    tax: (state) => state.tax,
    discount: (state) => state.discount,
    grandTotal: (state) => state.grandTotal,
    member: (state) => state.member
  },
  mutations: {
    [UPDATE_CART](state, payload){
      state.carts = payload;
    },
    [UPDATE_PAYMENT_METHOD](state, payload){
      state.paymentMethod = payload;
    },
    [UPDATE_TAX](state, payload){
      state.tax = payload;
    },
    [UPDATE_DISCOUNT](state, payload){
      state.discount = payload;
    },
    [UPDATE_GRAND_TOTAL](state, payload){
      state.grandTotal = payload;
    },
    [UPDATE_MEMBER](state, payload){
      state.member = payload;
    },
  },
  actions: {
    [SET_CART]({ commit }, payload){
      commit(UPDATE_CART, payload);
      
      if (payload.length == 0)
        commit(UPDATE_PAYMENT_METHOD, null);
    },
    [SET_PAYMENT_METHOD]({ commit }, payload){
      commit(UPDATE_PAYMENT_METHOD, payload);
    },
    [SET_TAX]({ commit }, payload){
      commit(UPDATE_TAX, payload);
    },
    [SET_DISCOUNT]({ commit }, payload){
      commit(UPDATE_DISCOUNT, payload);
    },
    [SET_GRAND_TOTAL]({ commit }, payload){
      commit(UPDATE_GRAND_TOTAL, payload);
    },
    [SET_MEMBER]({ commit }, payload){
      commit(UPDATE_MEMBER, payload);
    },
  },
};

export default pos;
