<template>
  <form-group
    :validator="v"
    :label="label"
    :description="description"
    :use-label="useLabel"
    :use-horizontal="useHorizontal"
  >
    <vue-editor v-model="model" :editorToolbar="customToolbar"></vue-editor>
  </form-group>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  props: {
    label: String,
    description: String,
    useLabel: {
      type: Boolean,
      default: true,
    },
    useHorizontal: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    v: Object,
    value: [Array, String, Number, Boolean],
    options: Array,
    multiple: {
      type: Boolean,
      default: true,
    },
    normalizer: {
      type: Function,
    },
  },
  data: () => ({
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
