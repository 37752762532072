<template>
  <form-group
    :validator="v"
    :label="label"
    :description="description"
    :use-label="useLabel"
    :use-horizontal="useHorizontal"
  >
    <b-form-file
      v-if="!useAvatar"
      slot-scope="{ attrs }"
      v-bind="attrs"
      v-model="model"
      :accept="accept"
      :size="size"
      :multiple="multiple"
      @change="onChange"
    />
    <!-- <div v-else class="image-input image-input-outline">
      <div
        class="image-input-wrapper"
        :style="`background-image: url(${imageUrl})`"
      />
      <label
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="change"
        data-toggle="tooltip"
        title=""
        data-original-title="Change avatar"
      >
        <i class="fa fa-pen icon-sm text-muted"></i>
        <input type="file" :accept="accept" @change="onChange" />
      </label>
    </div> -->
  </form-group>
</template>

<script>
export default {
  props: {
    label: String,
    description: String,
    useAvatar: {
      type: Boolean,
      default: false,
    },
    useLabel: {
      type: Boolean,
      default: true,
    },
    useHorizontal: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "lg",
    },
    accept: String,
    v: Object,
    value: [File, Array],
    imageUrl: String,
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onChange(event) {
      this.$emit("onChange", event);
    },
  },
};
</script>
