import Vue from "vue";

import CardForm from "@/view/content/common/CardForm";
import CardTable from "@/view/content/common/CardTable";
import EmptyTable from "@/view/content/common/EmptyTable";
import DateRange from "@/view/content/common/DateRange";
import Dropdown from "@/view/content/common/Dropdown";
import File from "@/view/content/common/File";
import Password from "@/view/content/common/Password";
import Phone from "@/view/content/common/Phone";
import PlainText from "@/view/content/common/PlainText";
import RadioGroup from "@/view/content/common/RadioGroup";
import InputMoney from "@/view/content/common/InputMoney";
// import InputSuggest from "@/view/content/common/InputSuggest";
import InputPlainText from "@/view/content/common/InputPlainText";
import InputText from "@/view/content/common/InputText";
import Select from "@/view/content/common/Select";
import Tags from "@/view/content/common/Tags";
import TextArea from "@/view/content/common/TextArea";
import TextEditor from "@/view/content/common/TextEditor";
import Time from "@/view/content/common/Time";
// import WizardForm from "@/view/content/common/wizard";
// import WizardStep from "@/view/content/common/wizard/Step";
// import WizardAction from "@/view/content/common/wizard/Action";
// import WizardNav from "@/view/content/common/wizard/Nav";

Vue.component("CardForm", CardForm);
Vue.component("CardTable", CardTable);
Vue.component("EmptyTable", EmptyTable);
Vue.component("DateRange", DateRange);
Vue.component("Dropdown", Dropdown);
Vue.component("File", File);
Vue.component("Password", Password);
Vue.component("Phone", Phone);
Vue.component("PlainText", PlainText);
Vue.component("RadioGroup", RadioGroup);
Vue.component("Tags", Tags);
Vue.component("InputMoney", InputMoney);
// Vue.component("InputSuggest", InputSuggest);
Vue.component("InputPlainText", InputPlainText);
Vue.component("InputText", InputText);
Vue.component("Select", Select);
Vue.component("TextArea", TextArea);
Vue.component("TextEditor", TextEditor);
Vue.component("Time", Time);
// Vue.component("WizardForm", WizardForm);
// Vue.component("WizardStep", WizardStep);
// Vue.component("WizardAction", WizardAction);
// Vue.component("WizardNav", WizardNav);
