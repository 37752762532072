export function clubMember() {
  return {
    path: "/club-member",
    redirect: "/checkin",
    component: () => import("@/view/pages/club-member"),
    children: [
      {
        path: "checkin",
        name: "checkIn",
        meta: {
          title: "Club Member System",
        },
        component: () => import("@/view/pages/club-member/checkin"),
      },
      {
        path: "member",
        component: () => import("@/view/pages/club-member/member"),
        children: [
          {
            path: "/",
            name: "member",
            meta: {
              title: "Member",
            },
            component: () =>
              import("@/view/pages/club-member/member/Table.vue"),
          },
          {
            path: "form",
            name: "memberCreate",
            meta: {
              title: "Registrasi Member Baru",
            },
            component: () => import("@/view/pages/club-member/member/Form"),
          },
          {
            path: "form/:id",
            name: "memberUpdate",
            meta: {
              title: "Ubah Member",
            },
            component: () => import("@/view/pages/club-member/member/Form"),
          },
        ],
      },
    ],
  };
}
