import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import Permission from "@/core/services/permission";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
// import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

import "@/core/plugins/message";
import "@/core/plugins/vuejs-dialog";
import "@/core/plugins/loading";
import "@/core/plugins/vuelidate";
import "@/core/plugins/v-money";
import "@/core/plugins/treeselect";
import "@/core/plugins/vue-fragment";
import "@/core/plugins/vue-cam-vision.js";
import "@/core/plugins/vue-print-nb.js";
import "@/core/plugins/vue-template-printer";
import "@/core/plugins/vue-lottie-player";
import "@/core/plugins/vue-html-to-paper";
import "@/core/plugins/v-viewer";
import "@/core/plugins/common";

// API service init
ApiService.init();
Permission.init();

// Remove this to disable mock API
// MockService.init();

// router.beforeEach((to, from, next) => {
//   // Ensure we checked auth before each page load.
//   // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

//   // reset config to initial state
//   // store.dispatch(RESET_LAYOUT_CONFIG);

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

new Vue({
  router,
  store,
  i18n,
  // vuetify,
  render: (h) => h(App),
}).$mount("#app");
