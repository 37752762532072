export function laporan() {
  return {
    path: "/laporan",
    redirect: "/laporan",
    component: () => import("@/view/pages/laporan"),
    children: [
      {
        path: "transaksi",
        name: "laporanTransaksi",
        meta: {
          title: "Laporan Transaksi",
        },
        component: () => import("@/view/pages/laporan/Transaksi"),
      },
      {
        path: "pengeluaran",
        name: "laporanPengeluaran",
        meta: {
          title: "Laporan Pengeluaran",
        },
        component: () => import("@/view/pages/laporan/Pengeluaran"),
      },
      {
        path: "shift",
        name: "laporanShift",
        meta: {
          title: "Laporan Shift",
        },
        component: () => import("@/view/pages/laporan/Shift"),
      },
      /* {
        path: "ringkasan-penjualan",
        name: "laporanRingkasanPenjualan",
        meta: {
          title: "Laporan Ringkasan Penjualan",
        },
        component: () => import("@/view/pages/laporan/RingkasanPenjualan.vue"),
      }, */
      /* {
        path: "penjualan",
        name: "laporanPenjualan",
        meta: {
          title: "Laporan Penjualan",
        },
        component: () => import("@/view/pages/laporan/penjualan"),
      }, */
      {
        path: "membership",
        name: "laporanMembership",
        meta: {
          title: "Membership",
        },
        component: () => import("@/view/pages/laporan/membership"),
      },
      {
        path: "checkin",
        name: "laporanCheckIn",
        meta: {
          title: "Check In Member",
        },
        component: () => import("@/view/pages/laporan/CheckIn"),
      },
      {
        path: "membercard-stock",
        name: "laporanMembercardStock",
        meta: {
          title: "Stok Kartu Member",
        },
        component: () => import("@/view/pages/laporan/MembercardStock"),
      },
    ],
  };
}
