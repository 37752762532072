// TODO : SET ENV
export const apiUrl = {
  prod: process.env.VUE_APP_BACKEND_URL_PROD ?? "https://backend.omset.co.id",
  dev: process.env.VUE_APP_BACKEND_URL_DEV ?? "https://localhost:7267",

  /*
  business: {
    name: "Demo",
    prodId: "402426f1-67bd-46b1-9087-d9190de4823f",
    devId: "402426f1-67bd-46b1-9087-d9190de4823f",
  },
  business: {
    name: "Numero Uno 2",
    prodId: "da3f7e6f-845c-4218-83fb-fc280f351d07",
    devId: "da3f7e6f-845c-4218-83fb-fc280f351d07",
  },
  */

  business: {
    name: process.env.VUE_APP_BUSINESS_NAME ?? "Kai",
    prodId:
      process.env.VUE_APP_BUSINESS_ID_PROD ??
      "978f5d99-5104-4fe2-a3ba-a3e043f1b522",
    devId:
      process.env.VUE_APP_BUSINESS_ID_DEV ??
      "978f5d99-5104-4fe2-a3ba-a3e043f1b522",
  },

  print: {
    prod: process.env.VUE_APP_PRINT_PROD ?? "https://print.omset.co.id",
    dev: process.env.VUE_APP_PRINT_DEV ?? "https://localhost:7299",
  },

  backoffice: {
    prod:
      process.env.VUE_APP_BACKOFFICE_PROD ?? "https://backoffice.omset.co.id",
    dev: process.env.VUE_APP_BACKOFFICE_DEV ?? "http://localhost:3000",
  },
};
