export function transaction() {
  return {
    path: "/transaction",
    redirect: "/pos",
    component: () => import("@/view/pages/transaction"),
    children: [
      {
        path: "pos",
        name: "pos",
        meta: {
          title: "POS System",
        },
        component: () => import("@/view/pages/transaction/pos"),
      },
      {
        path: "pengeluaran",
        component: () => import("@/view/pages/transaction/pengeluaran"),
        children: [
          {
            path: "/",
            name: "pengeluaran",
            meta: {
              title: "Pengeluaran",
            },
            component: () =>
              import("@/view/pages/transaction/pengeluaran/Table.vue"),
          },
          {
            path: "form",
            name: "pengeluaranCreate",
            meta: {
              title: "Buat Pengeluaran",
            },
            component: () =>
              import("@/view/pages/transaction/pengeluaran/Form"),
          },
          {
            path: "form/:id",
            name: "pengeluaranUpdate",
            meta: {
              title: "Ubah Pengeluaran",
            },
            component: () =>
              import("@/view/pages/transaction/pengeluaran/Form"),
          },
        ],
      },
      {
        path: "outstanding",
        component: () => import("@/view/pages/transaction/outstanding"),
        children: [
          {
            path: "/",
            name: "outstanding",
            meta: {
              title: "Transaksi Outstanding",
            },
            component: () =>
              import("@/view/pages/transaction/outstanding/Table.vue"),
          },
          {
            path: "form/:id",
            name: "outstandingUpdate",
            meta: {
              title: "Transaksi Outstanding",
              showHeader: true,
            },
            component: () =>
              import("@/view/pages/transaction/outstanding/Form"),
          },
        ],
      },
    ],
  };
}
